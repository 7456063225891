body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: auto;
}

.login-container {
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  height: 100vh;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  padding: 20px;
  box-sizing: border-box; 
}

.login-box {
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  text-align: center;
}

.login-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.login-box h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; 
}

.form-group a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.form-group button {
  width: 100%;
  padding: 10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.form-group button:hover {
  background-color: #c82333;
}

.password-group {
  display: flex;
  align-items: center;
}

.password-toggle-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .login-container {
    padding: 10px; 
  }

  .login-box {
    padding: 20px; 
    max-width: 100%; 
  }

  .form-group button {
    font-size: 14px; 
  }
}
