.stats-card {
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  display: flex;
  align-items: center;
  justify-content: left;
}

.stats-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.stat-title {
  display: flex;
  align-items: center;
}

.stat-value {
  font-weight: bold;
}

.vertical-divider {
  height: 50px !important;  
  width: 1px;
  background-color: rgba(0, 0, 0, 0.662);
}

.stat-item {
  margin-right: 3rem !important;
}
.css-46bh2p-MuiCardContent-root:last-child{
  padding: 10px 10px !important;
}