.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.header-logo img {
  height: 40px;
}

.header-title {
  flex: 1;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.header-username {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.header-username span {
  padding: 10px;
}
