@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova", Arial, sans-serif;
  font-size: 14px;
}
