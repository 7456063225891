.navbar {
  background-color: #151515;
  color: white;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure it takes full width */
}

.navbar-modules {
  display: flex;
  align-items: center;
}

.navbar-module {
  padding: 6px 10px;
  cursor: pointer;
  color: white;
  display: inline-block;
}

.navbar-module.active {
  background-color: #ED1C25;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.navbar-dropdowns {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes the dropdowns to the far right */
}

.navbar-dropdowns .timezone-select,
.navbar-dropdowns .mass-select {
  margin-left: 10px;
  margin-right: 5px;
  height: 25px;
  background-color: white;
}

.navbar_submenu {
  padding-left: 15px;
  padding-bottom: 3px;
  padding-top: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ED1C25;
  color: #fff;
}

.navbar-links-submenu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}

.navbar-links-submenu div {
  margin-right: 20px;
}

.navbar-submenu-item {
  cursor: pointer;
  color: white;
}

.navbar-submenu-item.active {
  border-bottom: 3px solid white;
  border-radius: 3px;
  font-weight: 600;
}
.button-white-bg {
  margin-left: 10px;
  margin-right: 5px;
  height: 25px;
  background-color: white;
  color: black; /* Text color set to black */
  border: 1px solid #ccc; /* Optional: Adds a border to give some contrast */
  transition: background-color 0.3s ease; /* Optional: Smooth transition for hover effect */
}

.button-white-bg:hover {
  background-color: white;
  color: black; /* Ensures text color remains black on hover */
  border: 1px solid #ccc; /* Optional: Keeps the border on hover */
}
