.module-card {
  position: relative;
  width: 100%;
  max-width: 250px; 
  height: 150px; 
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: transform 0.3s;
  display: flex; 
  flex-direction: column; 
}

.module-card img {
  width: 107%;
  height: 100%;
  object-fit: cover; 
}

.module-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.module-action {
  position: absolute;
  bottom: 10px;
  right: 50%;
  transform: translateX(50%);
  color: white;
  z-index: 1;
  transition: transform 0.3s;
}

.module-action.rotate {
  transform: translateX(50%) rotate(180deg);
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  display: none;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 2;
  transform: translateY(10px);
}

.sub-menu.visible {
  display: flex;
}

.sub-menu-item {
  text-align: center;
}

.custom-divider {
  margin: 0 auto;
  width: 80%;
  left: 20px;
  position: relative;
}

.module-card.hovered {
  transform: translateY(-10px);
}

@media (max-width: 768px) {
  .module-card {
    max-width: 45%;
  }
}

@media (max-width: 480px) {
  .module-card {
    max-width: 90%;
  }
}
