.home-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  background-color: #F5F5F5;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px; 
  padding: 20px;
}

@media (max-width: 768px) {
  .main-content {
    justify-content: center; 
  }
}

@media (max-width: 480px) {
  .main-content {
    flex-direction: column; 
    align-items: center; 
  }
}
