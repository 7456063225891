.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-content {
  flex: 1;
  padding: 20px;
  padding-top: 8px;
  background-color: #f5f5f5;
}
